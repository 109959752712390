import { defineNuxtRouteMiddleware, navigateTo } from "#imports";

const isAuthenticated = () => {
  return true
}
export default defineNuxtRouteMiddleware((to, from) => {
  if (isAuthenticated() === false) {
    return navigateTo('/login')
  }
  if(to.path === '/profile'){
    console.log(to.path);
  }
});
